import "../css/index.sass"
import * as React from "react"

import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Wallpapers from "../components/wallpapers"
import HeaderActions from "../components/headerActions"
import PageTitle from "../components/pageTitle"


const IndexPage = () => {
  return (
    <main>
      <PageTitle title="Wallpapers" />
      <Header/>
      <HeaderActions />
      <Wallpapers />
      {/* <AnalyticsConfirm /> */}
      <Footer />
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)