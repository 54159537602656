import React from "react"
import BellthurPreview from "../images/wallpapers/bellthur/preview.jpg"
import Bellthur1920x1080 from "../images/wallpapers/bellthur/wallpaper_bellthur_1920x1080.jpg"
import Bellthur2560x1440 from "../images/wallpapers/bellthur/wallpaper_bellthur_2560x1440.jpg"
import SenexPreview from "../images/wallpapers/senex/preview.jpg"
import Senex1920x1080 from "../images/wallpapers/senex/wallpaper_senex_1920x1080.jpg"
import Senex2560x1440 from "../images/wallpapers/senex/wallpaper_senex_2560x1440.jpg"
import LandscapePreview from "../images/wallpapers/landscape/preview.jpg"
import Landscape1920x1080 from "../images/wallpapers/landscape/wallpaper_landscape_1920x1080.jpg"
import Landscape2560x1440 from "../images/wallpapers/landscape/wallpaper_landscape_2560x1440.jpg"
import ThanaPreview from "../images/wallpapers/thana/preview.jpg"
import Thana1920x1080 from "../images/wallpapers/thana/wallpaper_thana_1920x1080.jpg"
import Thana2560x1440 from "../images/wallpapers/thana/wallpaper_thana_2560x1440.jpg"
import VioPreview from "../images/wallpapers/vio/preview.jpg"
import Vio1920x1080 from "../images/wallpapers/vio/wallpaper_vio_1920x1080.jpg"
import Vio2560x1440 from "../images/wallpapers/vio/wallpaper_vio_2560x1440.jpg"

import PreviewBorderImg from "../images/wallpapers/frame.png"

const wallpapers = [
  { 
    preview: BellthurPreview, 
    preview_alt: "core-and-combat-overview",
    resolutions: [
      {
        text: "1920x1080",
        link: Bellthur1920x1080
      }, {
        text: "2560x1440",
        link: Bellthur2560x1440
      }
    ]
  }, { 
    preview: SenexPreview,
    preview_alt: "core-and-combat-overview",
    resolutions: [
      {
        text: "1920x1080",
        link: Senex1920x1080
      }, {
        text: "2560x1440",
        link: Senex2560x1440
      }
    ]
  }, { 
    preview: LandscapePreview,
    preview_alt: "core-and-combat-overview",
    resolutions: [
      {
        text: "1920x1080",
        link: Landscape1920x1080
      }, {
        text: "2560x1440",
        link: Landscape2560x1440
      }
    ]
  }, { 
    preview: ThanaPreview,
    preview_alt: "core-and-combat-overview",
    resolutions: [
      {
        text: "1920x1080",
        link: Thana1920x1080
      }, {
        text: "2560x1440",
        link: Thana2560x1440
      }
    ]
  }, { 
    preview: VioPreview,
    preview_alt: "core-and-combat-overview",
    resolutions: [
      {
        text: "1920x1080",
        link: Vio1920x1080
      }, {
        text: "2560x1440",
        link: Vio2560x1440
      }
    ]
  }
];

const Wallpapers = () => {
  
  return (
    <div className="section section--wallpapers">
      <h2 className="wallpapers__title">Wallpapers</h2>
      <div className="wallpapers__content">
        {wallpapers.map((wallpaper, i) => (
          <div className="wallpapers__container">
            <div className="wallpapers__imageContainer">
              <img className="wallpapers__border" src={PreviewBorderImg}></img>
              <img className="wallpapers__previewImage" src={wallpaper.preview} alt={wallpaper.preview_alt}></img>
            </div>
            {wallpaper.resolutions.map((resolution) => (
              <a className="wallpapers__hyperlink" href={resolution.link}>{resolution.text}</a>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
};

export default Wallpapers;