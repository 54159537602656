import React, { useEffect } from "react";

interface PageTitleProps {
  title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  useEffect(() => {
    if (title) {
      const originalTitle = document.title;
      document.title = title;

      // Reset the document title when the component is unmounted
      return () => {
        document.title = originalTitle;
      };
    }
  }, [title]);

  return null;
};

export default PageTitle;